import { Box, Typography, Grid, styled } from '@mui/material';
import { ComponentProps } from 'react';

import { Attribute, Product } from '../../types/catalog';
import { formatDate } from '../../utils/format';

import {
  getProductDayWeekTitle,
  getProductWeekTitle,
  removeDuplicates,
} from '@app/utils/catalog';
import { unescapeAndConvertNewLines, unescapeHtml } from '@app/utils/pattern';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function DetailGridItem({
  label,
  children,
  ...props
}: ComponentProps<typeof Grid> & { label: string }) {
  return (
    <Grid
      item
      xs={6}
      display="flex"
      flexDirection="column"
      gap={1}
      {...props}
      pr={8}
    >
      <Typography fontWeight={700}>{label}</Typography>
      <ColBox>{children}</ColBox>
    </Grid>
  );
}

export interface ProductDetailComponentProps {
  attributeClinicalDepartmentList?: Attribute[];
  attributeJobTypeList?: Attribute[];
  product: Product;
}

export function ProductDetailComponent({
  product,
}: ProductDetailComponentProps) {
  const renderSchedule = (product: Product) => {
    const schedule = getProductDayWeekTitle(product);
    if (typeof schedule === 'string') {
      const dates = schedule.split(', ');
      const uniqueDates = removeDuplicates(dates);
      return uniqueDates.map((date: string, index: number) => (
        <span
          key={index}
          style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        >
          {date}
          {index < uniqueDates.length - 1 ? ', ' : ''}
        </span>
      ));
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <DetailGridItem label="プラン詳細">
          <Typography>
            {unescapeAndConvertNewLines(product.description)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="予約について">
          <Typography>
            {product.customFields?.reservationApproval
              ? '承認制'
              : '自動で予約確定'}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="PR条件">
          <Typography>
            {unescapeAndConvertNewLines(product.customFields?.prRules)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="当日予約について">
          <Typography>
            {product.customFields?.canBookToday
              ? '当日予約可能'
              : '当日予約不可'}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="予約時の注意事項">
          <Typography>
            {unescapeAndConvertNewLines(product.customFields.reservationNotes)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="プラン画像">
          {product.images && product.images.length > 0 && (
            <div style={{ height: '250px', padding: '10px', width: '100%' }}>
              <img
                alt="shop"
                style={{
                  height: '100%',
                  objectFit: 'contain',
                  width: 'auto',
                }}
                src={product.images[product.images.length - 1].url} //最新の画像
              />
            </div>
          )}
        </DetailGridItem>
        <DetailGridItem label="その他情報">
          <Typography>
            {unescapeAndConvertNewLines(product.additionalInformation)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="来店先">
          <RowBox gap={1}>
            <Typography>
              {product.locations.find((l) => l.type === 'prefecture')?.name}
            </Typography>
            <Typography>
              {product.locations.find((l) => l.type === 'city')?.name}
            </Typography>
          </RowBox>
          <Typography>
            {unescapeHtml(product?.organization?.name || '')}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="予約可能日・時間">
          <Typography>
            {product.customFields.scheduleType === 'day' ? (
              <>{renderSchedule(product)}</>
            ) : (
              <>{getProductWeekTitle(product)}</>
            )}
          </Typography>
          <Typography>
            {product.customFields.startTime}~{product.customFields.endTime}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="最大予約人数">
          <Typography>{product.customFields?.maxBookingPeople}名</Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="最小予約人数">
          <Typography>{product.customFields?.minBookingPeople}名</Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="予約上限数">
          <Typography>{product.customFields?.maxReservations}組</Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="通常の金額">
          <Typography>
            {product.customFields?.originPrice != null
              ? unescapeHtml(`${product.customFields?.originPrice}円/人`)
              : unescapeHtml('-')}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="割引金額">
          <Typography>
            {product.customFields?.price != null
              ? unescapeHtml(`${product.customFields?.price}円/人`)
              : unescapeHtml('-')}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="キーワード">
          <Typography>
            {product.tags.map((tag) => unescapeHtml(tag)).join(', ')}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label=""></DetailGridItem>
        <DetailGridItem label="掲載期間">
          <Typography>
            {formatDate(product.publication?.since)}～
            {formatDate(product.publication?.until)}
          </Typography>
        </DetailGridItem>
      </Grid>
    </Box>
  );
}
