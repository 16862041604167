import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Typography,
  TextField,
  FormHelperText,
  Select,
  Grid,
  Divider,
  useTheme,
  styled,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  format as dateFnsFormat,
  addMonths,
  addDays,
  isBefore,
  isAfter,
} from 'date-fns';
import {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  ComponentProps,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import {
  getSingleProduct,
  createProduct,
  updateProduct,
  fetchCategories,
  deleteProduct,
  uploadBlob,
  getAttributesByName,
} from '../../adapter/catalog-service';
import {
  snackbarOpenState,
  snackbarTextState,
  snackbarSeverityState,
} from '../../domain/app';
import { organizationSelector } from '../../domain/organization';
import {
  ProductForm,
  productFormSchema,
  defaultProductForm,
} from '../../schemas/catalog';
import { theme } from '../../theme';
import {
  SupportedProductPublicationStatus,
  Product,
  ProductInput,
  Category,
  ScheduleType,
} from '../../types/catalog';
import { convertNewLine } from '../../utils';
import { getMenuItems } from '../../utils/components';
import {
  productEndPeriodStatusList,
  defaultProductValue,
  productCategoryList,
  defaultProductInput,
  productStatusList,
  scheduleTypeList,
  categoryParentName,
} from '../../utils/constants';
import { formatDatesForRepeatWeek } from '../../utils/format';
import { DateField } from '../Shared/DateField';
import { NumberField } from '../Shared/NumberField';
import { TimeSelect } from '../Shared/TimeSelect';

import { ConfirmDialog } from './ConfirmDialog';
import { ProductDateSetting } from './ProductDateSetting';
import { ProductDetail, ProductDetailState } from './ProductDetail';
import { ProductLayout } from './ProductLayout';
import { ProductRepeatWeek } from './ProductRepeatWeek';
import { UploadFile } from './UploadFile/UploadFile';

import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';
import { SearchResult } from '@app/types/organization';
import { filterRecentDates } from '@app/utils/catalog';
import { resizeImageURL } from '@app/utils/image';
import {
  unescapeHtml,
  unescapeHtmlAndConvertNewLinesToHtml,
} from '@app/utils/pattern';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function ItemLabel(props: {
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  const theme = useTheme();
  return (
    <RowBox alignItems="center" gap={2}>
      <RowBox alignItems="center" gap={0.5}>
        <Typography>{props.title}</Typography>
        {props.required && (
          <Typography color={theme.customPalette.alert}>*</Typography>
        )}
      </RowBox>
      {props.message}
    </RowBox>
  );
}

function FormControlItem(props: {
  children: ReactNode;
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  return (
    <ColBox gap={1}>
      <ItemLabel
        title={props.title}
        required={props.required}
        message={props.message}
      />
      <ColBox>{props.children}</ColBox>
    </ColBox>
  );
}

function toCategoryEnum(categoryId: string, categoryList: Category[]) {
  return productCategoryList.find(
    (c) => c.name === categoryList.find((c2) => c2.id === categoryId)?.name
  )?.id as ProductForm['categoryEnum'] | undefined;
}

function toCategoryId(
  categoryEnum: ProductForm['categoryEnum'],
  categoryList: Category[]
) {
  return categoryList.find(
    (c) =>
      c.name === productCategoryList.find((c2) => c2.id === categoryEnum)?.name
  )?.id;
}

export interface ProductCreationState {
  id?: string;
  mode?: 'create' | 'copy' | 'edit';
}

export function ProductCreation() {
  const navigate = useNavigate();
  const location = useLocation();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  // プレビューモードかどうかの状態
  const [isPreview, setIsPreview] = useState(false);
  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);
  // プラン作成タイプリストの状態
  const [, setScheduleType] = useState<'day' | 'week'>('day');
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [isLoadingDraftSave, setIsLoadingDraftSave] = useState(false);
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);

  const productState = location.state as ProductCreationState | null;

  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [imageUris, setImageUris] = useState<string[]>([]);
  const [imageIds] = useState<string[]>([]);

  const [previewProductInput, setPreviewProductInput] =
    useState<ProductInput | null>(null);

  const { control, handleSubmit, watch, reset, getValues, setValue } =
    useForm<ProductForm>({
      defaultValues: {
        ...defaultProductForm,
        access: convertNewLine(organizationState.customFields.access ?? ''),
        workAddress1: organizationState.addressLine3 ?? '',
        workAddress2: organizationState.customFields.addressLine4 ?? '',
        workCity: organizationState.addressLine2 ?? '',
        workPostalCode: organizationState.postalCode ?? '',
      },
      mode: 'onChange',
      resolver: zodResolver(productFormSchema),
    });

  /** APIに渡すためのプランデータに変換 */
  const convertProduct = useCallback(
    async (
      formValue: ProductForm,
      status: SupportedProductPublicationStatus,
      days?: string[]
    ): Promise<ProductInput> => {
      const defaultEndPeriod = new Date(
        new Date().setFullYear(new Date().getFullYear() + 10)
      );
      const startPeriod = new Date(formValue.startPeriod);
      // 掲載終了日の指定
      let endPeriod;
      if (formValue.scheduleType === 'week') {
        endPeriod = new Date(formValue.endPeriod || '');
      } else if (formValue.endPeriodStatus === 'prev' && days) {
        endPeriod = addDays(new Date(days[days.length - 1]), -1);
      } else if (formValue.endPeriodStatus === 'daysAgo' && days) {
        endPeriod = addDays(
          new Date(days[days.length - 1]),
          (formValue.endPeriodDayCount ?? 0) * -1
        );
      } else if (formValue.endPeriod) {
        endPeriod = new Date(formValue.endPeriod);
      } else {
        endPeriod = defaultEndPeriod;
      }

      const filteredDays = days ? filterRecentDates(days) : [];
      // scheduleType === 'day'の場合はそのまま日付を使用。'week'の場合はformValueとformValue.endPeriodの日付から該当日付を計算して格納。
      let selectedDays: string[] = [];
      if (formValue.scheduleType === 'week' && endPeriod) {
        selectedDays = formatDatesForRepeatWeek(
          startPeriod,
          formValue.endPeriod || '',
          formValue.repeatWeek
        );
      } else if (filteredDays.length > 0) {
        selectedDays = filteredDays.map((day) =>
          dateFnsFormat(new Date(day), 'yyyy-MM-dd')
        );
      }
      const repeatWeek =
        days && days.length > 0
          ? Array.from(new Set(days.map((day) => new Date(day).getDay())))
          : [];

      // 店舗のジャンルを取得してプランのAttributesに登録
      const genres = organizationState.customFields.genre ?? [];
      let genreAttributes: SearchResult[] = [];
      try {
        if (genres.length > 0) {
          const response = await getAttributesByName({
            filter: {
              groupName: 'genre',
              name: genres.slice(0, 2),
            },
          });
          genreAttributes = response.data.value;
        }
      } catch (error) {
        console.error('Attributesの取得中にエラーが発生しました:', error);
        setSnackbarText('Attributesの取得中にエラーが発生しました');
        setSnackbarOpen(true);
      }

      const data: ProductInput = {
        ...defaultProductInput,
        additionalInformation: formValue.additionalInformation,
        attributes: genreAttributes.map((attr) => ({
          attributeId: attr.id,
          value: attr.name,
        })),
        categoryId: toCategoryId(formValue.categoryEnum, categoryList) ?? '',
        customFields: {
          access: formValue.workLocationOther
            ? formValue.access
            : organizationState.customFields.access ?? '',
          canBookToday: formValue.canBookToday,
          days: selectedDays,
          endTime: formValue.endTime,
          maxBookingPeople: formValue.maxBookingPeople ?? 0,
          maxReservations: formValue.maxReservations ?? 0,
          minBookingPeople: formValue.minBookingPeople ?? 0,
          originPrice: Number(formValue.originPrice),
          prRules: formValue.prRules,
          price: formValue.price,
          repeatWeek:
            formValue.scheduleType === 'day'
              ? repeatWeek
              : formValue.repeatWeek,
          reservationApproval: formValue.reservationApproval,
          reservationNotes: formValue.reservationNotes,
          scheduleType:
            formValue.scheduleType === 'day'
              ? ScheduleType.DAY
              : ScheduleType.WEEK,
          startTime: formValue.startTime,
          workAddress1: formValue.workLocationOther
            ? formValue.workAddress1
            : organizationState.addressLine3 ?? '',
          workAddress2: formValue.workLocationOther
            ? formValue.workAddress2
            : organizationState.customFields.addressLine4 ?? '',
          workPostalCode: formValue.workLocationOther
            ? formValue.workPostalCode
            : organizationState.postalCode ?? '',
        },
        description: formValue.description,
        imageIds: formValue.imageIds,
        locationIds: [
          formValue.workLocationOther
            ? formValue.workPrefecture
            : organizationState.addressLine1 ?? '',
          formValue.workLocationOther
            ? formValue.workCity
            : organizationState.addressLine2 ?? '',
        ],
        name: formValue.name,
        publication: {
          since: startPeriod.toISOString(),
          status,
          until: endPeriod.toISOString(),
        },
        tags: formValue.tags,
        variants: [
          {
            description: formValue.reservationNotes,
            price: {
              amount: formValue.variantsPriceAmount ?? 0,
              currency: 'JPY',
              taxIncluded: true,
            },
            sku: formValue.variantsSku,
            ...(formValue.variantsId ? { id: formValue.variantsId } : {}), // NOTE:IDが既存の存在する場合はそれを更新
          },
        ],
      };
      return data;
    },
    [
      categoryList,
      organizationState.addressLine1,
      organizationState.addressLine2,
      organizationState.addressLine3,
      organizationState.customFields.access,
      organizationState.customFields.addressLine4,
      organizationState.postalCode,
      organizationState.customFields.genre,
      setSnackbarText,
      setSnackbarOpen,
    ]
  );

  /** 新規登録する */
  const registerNewProduct = useCallback(
    async (
      formValue: ProductForm,
      status: SupportedProductPublicationStatus
    ) => {
      setIsLoadingRegister(true);

      try {
        let newImageIds = imageIds;

        // 画像投稿処理
        if (imageBlob) {
          const attachment = await uploadBlob(organizationState.id, imageBlob);
          if (attachment.id !== undefined) {
            newImageIds = [...newImageIds, attachment.id];
          }
        }

        const updatedFormValue = {
          ...formValue,
          imageIds: newImageIds,
        };

        const data = await convertProduct(
          updatedFormValue,
          status,
          formValue.days
        );

        const result = await createProduct(data, organizationState.id);
        if (result.status !== 201) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        navigate('/product/view', {
          state: { id: result.data.id } as ProductDetailState,
        });
      } catch (error) {
        setSnackbarText(
          `プランの登録に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsLoadingRegister(false);
      }
    },
    [
      convertProduct,
      setSnackbarText,
      setSnackbarOpen,
      organizationState.id,
      navigate,
      imageBlob,
      imageIds,
    ]
  );

  /** 編集内容を登録する */
  const registerEditProduct = useCallback(
    async (formValue: ProductForm) => {
      if (!productState?.id) {
        return;
      }
      try {
        let newImageIds = [...formValue.imageIds];

        // 画像投稿処理
        if (imageBlob) {
          const attachment = await uploadBlob(organizationState.id, imageBlob);
          if (attachment.id !== undefined) {
            newImageIds = [...newImageIds, attachment.id];
          }
        }

        const updatedFormValue = {
          ...formValue,
          imageIds: newImageIds,
        };

        const data = await convertProduct(
          updatedFormValue,
          formValue.status,
          formValue.days
        );
        const result = await updateProduct(data, productState.id);
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        navigate('/product/view', {
          state: { id: productState.id } as ProductDetailState,
        });
      } catch (error) {
        setSnackbarText(
          `プランの更新に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      }
    },
    [
      convertProduct,
      setSnackbarText,
      setSnackbarOpen,
      productState,
      navigate,
      imageBlob,
      organizationState.id,
    ]
  );

  /** 下書き保存ボタンクリック処理 */
  const handleClickDraftSave: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      setIsLoadingDraftSave(true);
      await registerNewProduct(formValue, 'DRAFT');
      setIsLoadingDraftSave(false);
    },
    [registerNewProduct]
  );

  /** 削除ボタンクリック処理 */
  const handleDeleteClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(async () => {
    setShouldShowDeleteDialog(() => true);
  }, []);

  /** プレビューボタンクリック処理 */
  const handleClickPreview: Parameters<typeof handleSubmit>[0] =
    useCallback(async () => {
      const productInput = await convertProduct(
        getValues(),
        'ACTIVE',
        watch('days')
      );
      setPreviewProductInput(productInput);
      setIsPreview(true);
    }, [convertProduct, getValues, watch]);

  /** 登録ボタンクリック処理 */
  const handleClickRegister: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      setIsLoadingRegister(true);
      if (productState?.mode === 'edit') {
        await registerEditProduct(formValue);
      } else {
        await registerNewProduct(formValue, 'ACTIVE');
      }
      setIsLoadingRegister(false);
    },
    [registerEditProduct, registerNewProduct, productState]
  );

  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm || !productState?.id) {
        setShouldShowDeleteDialog(false);
        return;
      }
      try {
        setIsDeleting(true);
        await deleteProduct(productState.id);
        setSnackbarText(`${productState.id}のプランを削除しました。`);
        setSnackbarState('info');
        setSnackbarOpen(true);
        navigate('/product');
      } catch (error) {
        setSnackbarText(
          `プランの削除に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
      }
    },
    [productState, navigate, setSnackbarOpen, setSnackbarState, setSnackbarText]
  );

  /** プランデータの読み込み */
  const loadProduct = useCallback(
    async (categoryList: Category[]) => {
      if (
        !productState?.mode ||
        !productState.id ||
        productState?.mode === 'create'
      ) {
        return;
      }
      try {
        const result = await getSingleProduct(productState.id);
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }

        const product: Product = {
          ...defaultProductValue,
          ...result.data,
          customFields: {
            ...defaultProductValue.customFields,
            ...result.data.customFields,
          },
          ...(productState.mode === 'copy'
            ? {
                day: undefined,
                id: productState.id,
                publication: {
                  publishedAt: '',
                  publishedBy: '',
                  since: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
                  status: 'ACTIVE',
                  until: dateFnsFormat(
                    addDays(addMonths(new Date(), 6), -1),
                    'yyyy/MM/dd'
                  ),
                },
              }
            : undefined),
        };
        const categoryEnum =
          toCategoryEnum(product.categoryId, categoryList) ?? 'spot';
        const workPostalCode = product.customFields?.workPostalCode ?? '';
        const workCity =
          product.locations?.find((l) => l.type === 'city')?.id ?? '';
        const workPrefecture =
          product.locations?.find((l) => l.type === 'prefecture')?.id ?? '';
        const workAddress1 = product.customFields?.workAddress1 ?? '';
        const workAddress2 = product.customFields?.workAddress2 ?? '';
        const access = convertNewLine(product.customFields?.access ?? '');
        const isWorkLocationOther =
          organizationState.postalCode !== workPostalCode ||
          organizationState.addressLine1 !== workPrefecture ||
          organizationState.addressLine2 !== workCity ||
          organizationState.addressLine3 !== workAddress1 ||
          (organizationState.customFields.addressLine4 ?? '') !==
            workAddress2 ||
          convertNewLine(organizationState.customFields.access ?? '') !==
            access;
        if (product.images && product.images.length > 0) {
          const lastImageUrl = product.images[product.images.length - 1].url;
          setImageUris([lastImageUrl]);
        }
        const formValues: ProductForm = {
          access,
          additionalInformation: unescapeHtmlAndConvertNewLinesToHtml(
            product.additionalInformation ?? ''
          ),
          canBookToday: product.customFields?.canBookToday ?? false,
          categoryEnum,
          days: product.customFields?.days
            ? product.customFields.days.map((day) =>
                dateFnsFormat(new Date(day), 'yyyy/MM/dd')
              )
            : [],
          description: unescapeHtmlAndConvertNewLinesToHtml(
            product.description
          ),
          endPeriod: product.publication?.until
            ? dateFnsFormat(new Date(product.publication?.until), 'yyyy/MM/dd')
            : '',
          endPeriodDayCount: null,
          endPeriodStatus: 'day',
          endTime: product.customFields?.endTime ?? '',
          imageIds:
            product.images && product.images.length > 0
              ? [product.images[product.images.length - 1].id]
              : [],
          inputMode: productState.mode ?? 'create',
          maxBookingPeople: product.customFields?.maxBookingPeople,
          maxReservations: product.customFields?.maxReservations,
          minBookingPeople: product.customFields?.minBookingPeople,
          name: unescapeHtml(product.name) ?? '',
          originPrice: product.customFields?.originPrice
            ? unescapeHtml(product.customFields.originPrice.toString())
            : '',
          prRules: unescapeHtmlAndConvertNewLinesToHtml(
            product.customFields?.prRules ?? ''
          ),
          price: product.customFields?.price
            ? unescapeHtml(product.customFields.price.toString())
            : '',
          repeatWeek: product.customFields?.repeatWeek,
          reservationApproval:
            product.customFields?.reservationApproval ?? false,
          reservationNotes: unescapeHtmlAndConvertNewLinesToHtml(
            product.variants?.[product.variants.length - 1]?.description ?? ''
          ),

          scheduleType: product.customFields.scheduleType,
          // NOTE:spotの場合も登録はされているが項目は定期非常勤用のため設定しない
          startPeriod: product.publication?.since
            ? dateFnsFormat(new Date(product.publication?.since), 'yyyy/MM/dd')
            : '',
          startTime: product.customFields?.startTime ?? '',
          status:
            (product.publication.status as ProductForm['status']) ?? 'ACTIVE',
          tags: product.tags
            ? product.tags.map((tag) => unescapeHtml(tag))
            : [],
          variantsId:
            productState.mode !== 'copy'
              ? product.variants?.[product.variants.length - 1]?.id
              : undefined,
          // NOTE:複製する場合は登録時に新たなIDが設定されるようにここでは何も入れない
          variantsPriceAmount:
            product.variants?.[product.variants.length - 1]?.price.amount ??
            null,
          variantsSku:
            product.variants?.[product.variants.length - 1]?.sku ?? 'day',
          workAddress1,
          workAddress2,
          workCity,
          workLocationOther: isWorkLocationOther,
          workPostalCode,
          workPrefecture,
        }; // NOTE:念のためレスポンスデータに存在しない項目があれば初期値を設定
        reset(formValues);
      } catch (error) {
        setSnackbarText(
          `プランの取得に失敗しました, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      }
    },
    [
      reset,
      setSnackbarText,
      setSnackbarOpen,
      productState,
      organizationState.addressLine1,
      organizationState.addressLine2,
      organizationState.addressLine3,
      organizationState.customFields.addressLine4,
      organizationState.customFields.access,
      organizationState.postalCode,
      setImageUris,
    ]
  );

  /** プラン区分の読み込み */
  const loadCategories = useCallback(async () => {
    try {
      const result = await fetchCategories();
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const category = result.data.value.find(
        (c) => c.name === categoryParentName
      );
      const list = category?.children ?? [];
      setCategoryList(list);
      return list;
    } catch (error) {
      setSnackbarText(
        `プラン区分の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [];
    }
  }, [setSnackbarText, setSnackbarOpen]);

  useEffect(() => {
    void (async () => {
      const categoryList = await loadCategories();
      await loadProduct(categoryList);
      setIsLoadData(true);
    })();
  }, [loadProduct, loadCategories]);

  const handleCheckReservationDays = useCallback(() => {
    const reservationDays = getValues('days');
    if (!reservationDays?.length) return;
    const start = getValues('startPeriod');
    const end = getValues('endPeriod');
    const validDays = reservationDays.filter((date) =>
      end
        ? !isBefore(new Date(date), new Date(start)) &&
          !isAfter(new Date(date), new Date(end))
        : !isBefore(new Date(date), new Date(start))
    );
    setValue('days', validDays);
  }, [getValues, setValue]);

  // プレビュー表示時
  if (isPreview && previewProductInput) {
    return (
      <ProductDetail
        previewMode
        productInput={previewProductInput}
        onClose={() => setIsPreview(false)}
      />
    );
  }

  // 編集、複製の場合、プランデータが読み込めるまで内容を表示しない
  if (
    (productState?.mode === 'edit' || productState?.mode === 'copy') &&
    !isLoadData
  ) {
    return (
      <ProductLayout
        sx={{
          minWidth: 1000,
          px: 0,
        }}
      >
        {productState?.mode === 'edit' ? (
          <Typography variant="h5">{productState.id}を編集</Typography>
        ) : (
          <Typography variant="h5">新規登録</Typography>
        )}
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  return (
    <form>
      <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
        <RowBox alignItems="center" gap={1} px={2}>
          {productState?.mode === 'copy' ? (
            <Typography variant="h5">
              {productState.id}の複製を元に新規登録
            </Typography>
          ) : productState?.mode === 'edit' ? (
            <Typography variant="h5">{productState.id}を編集</Typography>
          ) : (
            <Typography variant="h5">新規登録</Typography>
          )}

          <RowBox alignItems="center" gap={2} ml="auto">
            {productState?.mode !== 'edit' ? (
              <LoadingButton
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ width: '8rem' }}
                onClick={handleSubmit(handleClickDraftSave)}
                loading={isLoadingDraftSave}
              >
                下書き保存
              </LoadingButton>
            ) : (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  bgcolor: theme.palette.common.white,
                  border: `solid 1px ${theme.customPalette.alert}`,
                  color: theme.customPalette.alert,
                  width: '5rem',
                }}
                onClick={handleDeleteClick}
              >
                削除
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                display: 'none', // TODO:プレビュー機能が実装されたら削除する
                width: '8rem',
              }}
              onClick={handleSubmit(handleClickPreview)}
            >
              プレビュー
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              sx={{ width: '8rem' }}
              onClick={handleSubmit(handleClickRegister)}
              loading={isLoadingRegister}
            >
              登録
            </LoadingButton>
          </RowBox>
        </RowBox>

        {productState?.mode === 'edit' && (
          <>
            <Divider />
            <ColBox p={2}>
              <FormControlItem title="ステータス" required>
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        {...field}
                        error={!!error}
                        sx={{
                          bgcolor: theme.palette.common.white,
                          width: '20rem',
                        }}
                        displayEmpty
                      >
                        {getMenuItems(productStatusList)}
                      </Select>
                      {error ? (
                        <FormHelperText error sx={{ ml: 2 }}>
                          {error.message}
                        </FormHelperText>
                      ) : undefined}
                    </>
                  )}
                />
              </FormControlItem>
            </ColBox>
          </>
        )}

        <Divider />

        <ColBox gap={2} pb={4} px={2}>
          <RowBox alignItems="center" gap={0.5}>
            <Typography color="red">*</Typography>
            <Typography>は必須事項です</Typography>
          </RowBox>
          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid item xs={5} display="flex" flexDirection="column" gap={2}>
              <FormControlItem title="プラン区分" required>
                <Controller
                  name="categoryEnum"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        {...field}
                        error={!!error}
                        sx={{ width: '20rem' }}
                      >
                        {getMenuItems(productCategoryList)}
                      </Select>
                      {error ? (
                        <FormHelperText error sx={{ ml: 2 }}>
                          {error.message}
                        </FormHelperText>
                      ) : undefined}
                    </>
                  )}
                />
              </FormControlItem>
              <FormControlItem title="プラン名" required>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                      name="product-name"
                      error={!!error}
                      sx={{ width: '20rem' }}
                      placeholder="プラン名を入力"
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
            </Grid>
            <Grid item xs={6.5} display="flex" flexDirection="column" gap={2}>
              <FormControlItem title="掲載期間" required>
                <RowBox alignItems="baseline" gap={2}>
                  <RowBox alignItems="baseline" gap={1}>
                    <Controller
                      name="startPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref}
                          onChange={(e) => {
                            field.onChange(e);
                            handleCheckReservationDays();
                          }}
                          format="yyyy/MM/dd"
                          error={!!error}
                          sx={{ width: '10rem' }}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Typography>～</Typography>
                    <Select value={'day'} sx={{ width: '9rem' }} disabled>
                      {getMenuItems(productEndPeriodStatusList)}
                    </Select>
                  </RowBox>
                  <RowBox alignItems="baseline" gap={1}>
                    <Typography>終了日</Typography>
                    <Controller
                      name="endPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref}
                          onChange={(e) => {
                            field.onChange(e);
                            handleCheckReservationDays();
                          }}
                          format="yyyy/MM/dd"
                          error={!!error}
                          sx={{ width: '10rem' }}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </RowBox>
                </RowBox>
              </FormControlItem>
            </Grid>
          </Grid>

          <Divider />

          <FormControlItem title="プラン作成タイプ" required>
            <Controller
              name="scheduleType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    {...field}
                    error={!!error}
                    sx={{ width: '20rem' }}
                    onChange={(e) => {
                      field.onChange(e);
                      setScheduleType(e.target.value as 'day' | 'week');
                    }}
                  >
                    {getMenuItems(scheduleTypeList)}
                  </Select>
                  {error ? (
                    <FormHelperText error sx={{ ml: 2 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </>
              )}
            />
          </FormControlItem>

          {watch('scheduleType') === 'day' ? (
            <>
              <Controller
                name="days"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControlItem
                    title="募集する来店日"
                    required
                    message={
                      error ? (
                        <Typography color="error" sx={{ fontWeight: 'bold' }}>
                          {error.message}
                        </Typography>
                      ) : null
                    }
                  >
                    <ProductDateSetting
                      {...field}
                      error={!!error}
                      helperText=""
                      startDate={watch('startPeriod') || undefined}
                      endDate={watch('endPeriod') || undefined}
                    />
                  </FormControlItem>
                )}
              />
              <Typography color={theme.palette.grey[500]}>
                半年先までのプランを一括で登録可能です。
              </Typography>
            </>
          ) : (
            <FormControlItem title="募集曜日" required>
              <Controller
                name="repeatWeek"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <ProductRepeatWeek
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControlItem>
          )}

          <FormControlItem title="予約時間" required>
            <RowBox alignItems="baseline" gap={2}>
              <Controller
                name="startTime"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimeSelect
                    {...field}
                    startTime="00:00"
                    endTime="23:55"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Typography>～</Typography>
              <Controller
                name="endTime"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimeSelect
                    {...field}
                    startTime="00:00"
                    endTime="96:55"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </RowBox>
          </FormControlItem>

          <FormControlItem title="予約上限数" required>
            <Controller
              name="maxReservations"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                  <NumberField
                    {...field}
                    ref={undefined}
                    inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                    sx={{ width: '10rem' }}
                    placeholder="予約上限数を入力"
                    error={!!error}
                    helperText={error?.message}
                  />
                  <Typography sx={{ pl: 1 }}>組まで</Typography>
                </Grid>
              )}
            />
          </FormControlItem>

          <Divider />

          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
              <FormControlItem title="プラン詳細" required>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                      error={!!error}
                      sx={{ width: '30rem' }}
                      multiline
                      rows={6}
                      placeholder="プラン詳細を入力"
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem required title="PR条件">
                <Controller
                  name="prRules"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                      error={!!error}
                      sx={{ width: '30rem' }}
                      multiline
                      rows={5}
                      placeholder="PR条件を入力"
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>

              <FormControlItem title="予約時の注意事項">
                <Controller
                  name="reservationNotes"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                      error={!!error}
                      sx={{ width: '30rem' }}
                      multiline
                      rows={5}
                      placeholder="予約時の注意事項を入力"
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
              <FormControlItem title="その他情報">
                <Controller
                  name="additionalInformation"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                      error={!!error}
                      sx={{ width: '30rem' }}
                      multiline
                      rows={5}
                      placeholder="その他情報を入力"
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControlItem>
            </Grid>

            <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
              <Grid item xs={10} sx={{ px: 3 }}>
                <FormControlItem title="予約について">
                  <Controller
                    name="reservationApproval"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        checked={field.value}
                        control={<Checkbox />}
                        label="予約を承認制にする"
                        sx={{ my: -0.5 }}
                        onChange={(e) =>
                          field.onChange((e.target as HTMLInputElement).checked)
                        }
                      />
                    )}
                  />
                  <Typography
                    sx={{ color: theme.customPalette.lightGray, mb: 2, mt: 1 }}
                  >
                    ※予約を承認制にする場合は上記チェックボックスにチェックを入れて作成してください。チェックがない場合は、予約があった際に即予約確定となります。
                  </Typography>
                </FormControlItem>

                <FormControlItem title="">
                  <Controller
                    name="canBookToday"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        checked={field.value}
                        control={<Checkbox />}
                        label="当日予約を許可する"
                        sx={{ my: -0.5 }}
                        onChange={(e) =>
                          field.onChange((e.target as HTMLInputElement).checked)
                        }
                      />
                    )}
                  />
                  <Typography
                    sx={{ color: theme.customPalette.lightGray, mb: 2, mt: 1 }}
                  >
                    ※当日予約を許可する場合は上記チェックボックスにチェックを入れて作成してください。
                    チェックがない場合は、当日予約が不可なります。当日予約可能な場合、予約可能時刻の3時間前まで予約可能になります。
                  </Typography>
                </FormControlItem>

                {/* 制御しにくいので ReactHookForm の管理外とする */}
                <FormControlItem title="プランイメージ・写真">
                  <UploadFile
                    sx={{ padding: '10px 10px 10px 0' }}
                    fileUrl={
                      imageUris.length > 0
                        ? imageUris[imageUris.length - 1]
                        : ''
                    }
                    setImageUrl={async (data: string) => {
                      if (data) {
                        const resizeUrl = await resizeImageURL(
                          data,
                          800,
                          534,
                          3 / 2
                        );
                        const response = await fetch(resizeUrl);
                        const blob = await response.blob();

                        setImageBlob(blob);
                        setImageUris((prevUris) => [...prevUris, resizeUrl]);
                      } else {
                        // 画像削除時のバグ対策
                        setImageBlob(null);
                      }
                    }}
                    setImageUris={setImageUris}
                    addImageButtonText="画像をアップロード"
                  />
                </FormControlItem>

                <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
                  <FormControlItem title="来店先" required>
                    <Select
                      value={organizationState.name}
                      disabled={watch('workLocationOther')}
                      sx={{
                        bgcolor: !watch('workLocationOther')
                          ? theme.palette.common.white
                          : theme.palette.action.disabledBackground,
                        mb: 2,
                        width: '25rem',
                      }}
                      displayEmpty
                    >
                      {getMenuItems([organizationState.name])}
                    </Select>
                  </FormControlItem>
                </Grid>

                <FormControlItem title="最大予約人数" required>
                  <Controller
                    name="maxBookingPeople"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Grid
                        sx={{ alignItems: 'center', display: 'flex', mb: 2 }}
                      >
                        <NumberField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                          sx={{ width: '10rem' }}
                          placeholder="最大予約人数を入力"
                          error={!!error}
                          helperText={error?.message}
                        />
                        <Typography sx={{ pl: 1 }}>名</Typography>
                      </Grid>
                    )}
                  />
                </FormControlItem>

                <FormControlItem title="最低予約人数" required>
                  <Controller
                    name="minBookingPeople"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Grid
                        sx={{ alignItems: 'center', display: 'flex', mb: 2 }}
                      >
                        <NumberField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                          sx={{ width: '10rem' }}
                          placeholder="最低予約人数を入力"
                          error={!!error}
                          helperText={error?.message}
                        />
                        <Typography sx={{ pl: 1 }}>名</Typography>
                      </Grid>
                    )}
                  />
                </FormControlItem>

                <FormControlItem title="通常金額">
                  <Controller
                    name="originPrice"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Grid
                        sx={{ alignItems: 'center', display: 'flex', mb: 2 }}
                      >
                        <TextField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                          sx={{ width: '30rem' }}
                          placeholder="1人あたりの通常金額"
                          error={!!error}
                          helperText={error?.message}
                          type="number"
                          inputProps={{ min: 1 }}
                        />
                        <Typography sx={{ pl: 1 }}>円/人</Typography>
                      </Grid>
                    )}
                  />
                  <Typography
                    sx={{ color: theme.customPalette.lightGray, mb: 2 }}
                  >
                    「4,000」など具体的に入力してください
                  </Typography>
                </FormControlItem>

                <FormControlItem title="割引金額">
                  <Controller
                    name="price"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Grid
                        sx={{ alignItems: 'center', display: 'flex', mb: 2 }}
                      >
                        <TextField
                          {...field}
                          ref={undefined}
                          inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                          sx={{ width: '30rem' }}
                          placeholder="1人あたりの金額・割引を入力"
                          error={!!error}
                          helperText={error?.message}
                          type="number"
                          inputProps={{ min: 1 }}
                        />
                        <Typography sx={{ pl: 1 }}>円/人</Typography>
                      </Grid>
                    )}
                  />
                  <Typography
                    sx={{ color: theme.customPalette.lightGray, mb: 2 }}
                  >
                    「4,000」など具体的に入力してください
                  </Typography>
                </FormControlItem>

                <FormControlItem title="キーワード" required>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        ref={undefined}
                        inputRef={field.ref}
                        error={!!error}
                        sx={{ width: '30rem' }}
                        multiline
                        placeholder="キーワードを入力"
                        helperText=""
                        onBlur={(e) => {
                          const tagsArray = e.target.value
                            .replace(/、/g, ',')
                            .split(/[\s,]+/)
                            .filter(Boolean);
                          field.onChange(tagsArray);
                        }}
                      />
                    )}
                  />
                  <Typography
                    sx={{ color: theme.customPalette.lightGray, mb: 2 }}
                  >
                    カンマや空白でキーワードを複数登録することが出来ます
                  </Typography>
                </FormControlItem>
              </Grid>
            </Grid>
          </Grid>
        </ColBox>
      </ProductLayout>
      <ConfirmDialog
        title="本当に削除しますか？"
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </form>
  );
}
