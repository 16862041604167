import { startOfMonth, endOfMonth } from 'date-fns';

import {
  Product,
  ProductInput,
  ProductCustomFields,
  ProductFeature,
  ProductStatus,
  SupportedProductPublicationStatus,
  ScheduleType,
} from '../types/catalog';
import { OrderStatus } from '../types/order';

export const STORAGE_KEYS = {
  /*
    localStorage, sessionStorage を使う場合はここに key を追加
    重複しないように一元管理
  */

  ACCESS_TOKEN: 'accessToken',
  FINGERPRINT: 'fingerprint',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
};

export const prefectureData = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const defaultFeature: Array<ProductFeature> = [
  {
    name: '女性店舗',
    value: '',
  },
  {
    name: '成果報酬型',
    value: '',
  },
  {
    name: '無料カウンセリング有り',
    value: '',
  },
  {
    name: '相談実績多数',
    value: '',
  },
  {
    name: '拠点多数',
    value: '',
  },
  {
    name: '認定資格有',
    value: '',
  },
];

export const localizedOrderStatus = {
  ACCEPTED: '受ける',
  CANCELED: '取消',
  CLOSED: '完了',
  PENDING: '未決',
  PROCESSING: '対応',
  WAITING: '',
};

export const defaultLineItem = {
  price: {
    amount: 0,
    taxable: true,
  },
  productId: '',
  productName: '',
  quantity: 0,
  sku: '',
  variant: '',
  variantTitle: '',
};

export const defaultOrderStatusFilters = [
  OrderStatus.ACCEPTED,
  OrderStatus.CLOSED,
  OrderStatus.PENDING,
  OrderStatus.PROCESSING,
];

export const defaultProductStatus = [
  ProductStatus.DRAFT,
  ProductStatus.ACTIVE,
  ProductStatus.ARCHIVED,
];

export const defaultProductCustomFields: ProductCustomFields = {
  access: '',
  canBookToday: false,
  days: [],
  endTime: '',
  maxBookingPeople: 1,
  maxReservations: 1,
  minBookingPeople: 1,
  originPrice: '',
  prRules: '',
  price: '',
  repeatWeek: [],
  reservationApproval: false,
  reservationNotes: '',
  scheduleType: ScheduleType.DAY,
  startTime: '',
  workAddress1: '',
  workAddress2: '',
  workPostalCode: '',
};

export const defaultProductValue: Product = {
  additionalInformation: '',
  attributes: [],
  category: {
    children: [],
    description: '',
    id: '',
    name: '',
    numberOfProducts: 0,
    parent: null,
    status: 'PENDING',
  },
  categoryId: '',
  customFields: { ...defaultProductCustomFields },
  description: '',
  dimensions: '',
  features: [],
  id: '',
  // images: [],
  imageIds: [],
  locations: [],
  name: '',
  organizationId: '',
  publication: {
    publishedAt: '',
    publishedBy: '',
    since: startOfMonth(new Date()).toISOString(),
    status: 'DRAFT' as SupportedProductPublicationStatus,
    until: endOfMonth(new Date()).toISOString(),
  },
  tags: [],
  variantNote: '',
  variants: [],
};

export const defaultProductInput: ProductInput = {
  additionalInformation: '',
  attributes: [],
  categoryId: '',
  customFields: { ...defaultProductCustomFields },
  description: '',
  dimensions: '',
  features: [],
  imageIds: [],
  // images: [],
  locationIds: [],
  name: '',
  publication: {
    since: startOfMonth(new Date()).toISOString(),
    status: 'DRAFT' as SupportedProductPublicationStatus,
    until: endOfMonth(new Date()).toISOString(),
  },
  tags: [],
  variantNote: '',
  variants: [],
};

export const productStatusList = [
  {
    id: 'ACTIVE',
    name: '掲載中',
  },
  {
    id: 'ARCHIVED',
    name: '終了',
  },
  {
    id: 'DRAFT',
    name: '下書き',
  },
];

export const productCategoryList = [
  {
    id: 'spot',
    name: 'レストラン',
  },
  // {
  //   id: 'regularPartTime',
  //   name: 'ビューティー',
  // },
];

export const categoryParentName = 'プラン区分';

export const scheduleTypeList = [
  {
    id: 'day',
    name: '日付別',
  },
  {
    id: 'week',
    name: '曜日',
  },
];

export const productEndPeriodStatusList = [
  {
    id: 'prev',
    name: '来店日前日',
  },
  {
    id: 'daysAgo',
    name: '来店日X日前',
  },
  {
    id: 'day',
    name: '日付指定',
  },
];

export const productVariantsSkuList = [
  {
    id: 'hour',
    name: '時給',
  },
  {
    id: 'day',
    name: '日給',
  },
];

export const productCommutingList = [
  {
    id: 'UpperLimit',
    name: '別途支給（上限金額）',
  },
  {
    id: 'NoUpperLimit',
    name: '別途支給（上限なし）',
  },
  {
    id: 'FixedFee',
    name: '別途支給（定額）',
  },
  {
    id: 'Include',
    name: '交通費込み（所定金額）',
  },
  {
    id: 'NoFee',
    name: 'なし',
  },
];

export const productSelectionList = [
  '',
  '対面面接必須',
  'オンライン面接可',
  '書類選考のみ',
];

// カルテ
export const SELECTABLE_CHARTS_TYPE = {
  ELECTRONIC: 'electronic',
  PAPER: 'paper',
};
export const CHARTS_LABEL = {
  [SELECTABLE_CHARTS_TYPE.ELECTRONIC]: '電子カルテ',
  [SELECTABLE_CHARTS_TYPE.PAPER]: '紙カルテ',
};

// 問い合わせ用 FORM
export const INQUIRY_FORM_URL = 'https://forms.gle/Sh65HGJqsAG2N1YL6'; // google forms
